'use client';

import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import useScrollLock from '@/shared/lib/hooks/useScrollLock';
import CrossIcon from '@/shared/ui/Icon/svg/cross.svg';

import * as SC from './styled';

export interface ModalProps
  extends Omit<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDialogElement>, HTMLDialogElement>,
    'title' | 'content'
  > {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  closable?: boolean;
  content: React.ReactNode;
  onClose?: () => void;
  isOpen: boolean;
  disableScroll?: boolean;
}

// copy from esimplusme
const Modal = React.forwardRef<any, ModalProps>(
  ({ title, subtitle, content, onClose, isOpen, closable = true, disableScroll = true, ...props }, ref) => {
    const closeModalButton = useMemo(
      () =>
        closable ? (
          <SC.CloseModalButton onClick={onClose}>
            <CrossIcon width={24} height={24} />
          </SC.CloseModalButton>
        ) : null,
      [onClose, closable],
    );

    const [mounted, setMounted] = React.useState(false);

    useEffect(() => setMounted(true), []);

    useEffect(() => {
      if (isOpen && closable && onClose) {
        const escapeKeyListener = (e: KeyboardEvent) => {
          if (e.code === 'Escape') {
            onClose();
          }
        };

        window.addEventListener('keydown', escapeKeyListener);

        return () => window.removeEventListener('keydown', escapeKeyListener);
      }

      return undefined;
    }, [isOpen, closable, onClose]);

    useScrollLock(Boolean(disableScroll && isOpen));

    if (!mounted) {
      return null;
    }

    return createPortal(
      <SC.FullscreenWrapper $isOpen={isOpen}>
        <SC.Wrapper ref={ref as any} {...props} open={isOpen}>
          <div>
            <SC.Content>
              {title && (
                <SC.Title>
                  <div>
                    <div>{title}</div>
                    {subtitle && <div>{subtitle}</div>}
                  </div>
                  {closeModalButton}
                </SC.Title>
              )}
              {!!closeModalButton && !title && (
                <SC.AbsoluteCloseButtonWrapper>{closeModalButton}</SC.AbsoluteCloseButtonWrapper>
              )}

              <SC.ContentContainer>{content}</SC.ContentContainer>
            </SC.Content>
          </div>
        </SC.Wrapper>
      </SC.FullscreenWrapper>,
      document.body,
    );
  },
);

Modal.displayName = 'Modal';

export default Modal;
