'use client'

import styled, { css } from 'styled-components'

export const CustomCheckbox = styled.span<{ $checked?: boolean }>(
  ({ $checked }) => css`
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid #cccccc;
    border-radius: 0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: border-color 0.2s linear;
    flex-shrink: 0;

    ${$checked &&
    css`
      border-color: #0b84ff;
      background-color: #0b84ff;
    `}

    svg {
      width: 1.25rem;
      height: 1.25rem;
      fill: #ffffff;
    }
  `,
)

export const LabelText = styled.span(
  () => css`
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff;
  `,
)

export const Root = styled.label<{
  $fullWidth?: boolean
  $reverse?: boolean
}>(
  ({ $fullWidth, $reverse }) => css`
    display: flex;
    flex-direction: ${$reverse ? 'row-reverse' : 'row'};
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: fit-content;
    gap: 0.5rem;
    -webkit-tap-highlight-color: transparent;

    @media (hover: hover) {
      &:hover {
        ${CustomCheckbox} {
          border-color: #0b84ff;
        }
      }
    }

    ${$fullWidth &&
    css`
      width: 100%;

      ${LabelText} {
        width: 100%;
      }
    `}
  `,
)

export const NativeInput = styled.input`
  display: none;
`
