import styled, { css } from 'styled-components'

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px;
  border-bottom: 1px solid var(--border-color);

  > div > div:first-child {
    padding: 0;
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #ffffff;
  }

  @media (max-width: 768px) {
    padding: 25px 16px 16px 16px;
  }
`

export const CloseModalButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 4px;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 6px;

  // svg {
  //   fill: ${(props) => props.theme.primaryText};
  // }
`

export const AbsoluteCloseButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 25px;
  top: 25px;
  z-index: 1;

  @media (max-width: 768px) {
    right: 16px;
  }
`

export const Content = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const ContentContainer = styled.div`
  position: relative;
  overflow: auto;
`

export const Wrapper = styled.dialog`
  padding: 0;
  margin: 0;
  border: none;
  overflow: hidden;
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000000;
  box-shadow: 0 25px 80px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  max-width: 650px;
  max-height: 80%;
  width: 100%;
  height: fit-content;
  transition: 0.2s all;
  z-index: 6;

  > div {
    position: relative;
    width: 100%;
  }

  @media (max-width: 768px) {
    max-width: 95%;
  }
`

export const FullscreenWrapper = styled.div<{ $isOpen: boolean }>`
  z-index: 7;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s all;
  background-color: rgba(0, 0, 0, 0.4);

  ${(props) =>
    props.$isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`
