import React from 'react'

function useModalControls(initState = false) {
  const [isOpen, setIsOpen] = React.useState(initState)

  const openModal = React.useCallback(() => setIsOpen(true), [])
  const closeModal = React.useCallback(() => setIsOpen(false), [])

  return {
    isOpen,
    openModal,
    closeModal,
  }
}

export default useModalControls
