'use client';

import React, { InputHTMLAttributes, useCallback } from 'react';
import CheckIcon from '@/shared/ui/Icon/svg/check.svg';

import type { BaseInputProps } from '../types';
import * as SC from './styled';

const TYPE = 'checkbox';

function Checkbox(props: CheckboxProps) {
  const { value, className, onChange, name, label, fullWidth, disabled, checked, reverse = false, id } = props;

  const updatedOnChange = useCallback<NonNullable<InputHTMLAttributes<HTMLInputElement>['onChange']>>(
    (e) => {
      onChange({ name, value: e.target.value, type: TYPE, checked: e.target.checked });
    },
    [name, onChange],
  );

  return (
    <SC.Root className={className} $fullWidth={fullWidth} $reverse={reverse}>
      <SC.NativeInput
        id={id}
        checked={checked}
        value={value}
        type={TYPE}
        name={name}
        onChange={updatedOnChange}
        disabled={disabled}
      />

      <SC.CustomCheckbox $checked={checked}>{checked && <CheckIcon />}</SC.CustomCheckbox>

      <SC.LabelText>{label}</SC.LabelText>
    </SC.Root>
  );
}

export default React.memo(Checkbox);

export interface CheckboxProps extends BaseInputProps {
  fullWidth?: boolean;
  checked: boolean;
  reverse?: boolean;
  id?: string;
}
