'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { useCookieConsent } from '@/processes/cookie-consent';
import setCookie from '@/shared/lib/helpers/setCookie';
import CookiesNames from '@/shared/lib/constants/cookiesNames';
import Button from '@/shared/ui/Button';
import Modal from '@/shared/ui/Modal';
import useModalControls from '@/shared/lib/hooks/useModalControls';
import Checkbox from '@/shared/ui/inputs/Checkbox';

import * as SC from './styled';

function GDPR() {
  const t = useTranslations();
  const [{ privacySettings, isConsentValueExist }, { updateSettings }] = useCookieConsent();
  const [isOpen, setIsOpen] = React.useState(!isConsentValueExist);

  const { isOpen: isSettingsModalOpen, openModal, closeModal } = useModalControls(false);
  const updatePrivacySettings = (config: Partial<typeof privacySettings>) => {
    updateSettings({ ...privacySettings, ...config });
  };

  const handleCookieConsent = (config: typeof privacySettings) => {
    setCookie(
      CookiesNames.CONSENT,
      new URLSearchParams(Object.entries(config).map(([key, val]) => [key, String(Number(val))])).toString(),
      30,
    );
    setIsOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Modal
        title={t('cookie_consent.settings_modal_title')}
        content={
          <SC.PrivacySettingsModalContent>
            <SC.PrivacySettingsItemsList>
              {(Object.keys(privacySettings) as (keyof typeof privacySettings)[]).map((key) => (
                <SC.PrivacySettingsItem key={key}>
                  <Checkbox
                    fullWidth
                    name="GDPR"
                    value={key}
                    checked={privacySettings[key]}
                    onChange={({ value, checked }) => {
                      updatePrivacySettings({ [value]: checked });
                    }}
                    label={
                      <SC.PrivacySettingsItemContent>
                        <SC.PrivacySettingsItemTitle>{t(`cookie_consent.${key}.title`)}</SC.PrivacySettingsItemTitle>
                        <SC.PrivacySettingsItemDescription>
                          {t(`cookie_consent.${key}.description`)}
                        </SC.PrivacySettingsItemDescription>
                      </SC.PrivacySettingsItemContent>
                    }
                    id={key}
                  />
                </SC.PrivacySettingsItem>
              ))}
            </SC.PrivacySettingsItemsList>
            <Button fullWidth label={t('cookie_consent.save')} size="sm" variant="primary" onClick={closeModal} />
          </SC.PrivacySettingsModalContent>
        }
        isOpen={isSettingsModalOpen}
        onClose={closeModal}
      />
      {!isSettingsModalOpen && (
        <SC.Wrapper>
          <SC.Title>{t('cookie_consent.title')}</SC.Title>
          <SC.Description>
            {t.rich('cookie_consent.text', {
              a: (chunks) => <a href="/privacy-policy">{chunks}</a>,
            })}
          </SC.Description>

          <SC.ControllerBlock>
            <SC.CustomizeSettingsButton onClick={openModal}>
              {t('cookie_consent.customize_settings')}
            </SC.CustomizeSettingsButton>

            <SC.ButtonsWrapper>
              <li>
                <Button
                  label={t('cookie_consent.accept_selected')}
                  size="xsm"
                  variant="secondary"
                  onClick={() => handleCookieConsent(privacySettings)}
                />
              </li>
              <li>
                <Button
                  label={t('cookie_consent.decline_all')}
                  size="xsm"
                  variant="outlined"
                  onClick={() => {
                    const newConfig = Object.fromEntries(
                      Object.entries(privacySettings).map(([key]) => [key, false]),
                    ) as typeof privacySettings;
                    updatePrivacySettings(newConfig);
                    handleCookieConsent(newConfig);
                  }}
                />
              </li>
              <li>
                <Button
                  label={t('cookie_consent.accept_all')}
                  size="xsm"
                  onClick={() => {
                    const newConfig = Object.fromEntries(
                      Object.entries(privacySettings).map(([key]) => [key, true]),
                    ) as typeof privacySettings;
                    updatePrivacySettings(newConfig);
                    handleCookieConsent(newConfig);
                  }}
                />
              </li>
            </SC.ButtonsWrapper>
          </SC.ControllerBlock>
        </SC.Wrapper>
      )}
    </>
  );
}

export default GDPR;
