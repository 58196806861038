import styled from 'styled-components'

export const PrivacySettingsItemContent = styled.span`
  font-size: 14px;
  line-height: 14px;
  display: flex;
  flex-direction: column;
`

export const PrivacySettingsItemTitle = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 5px;
`

export const PrivacySettingsItemDescription = styled.span`
  font-weight: 400;
  font-size: 14px;
`

export const PrivacySettingsItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
export const PrivacySettingsModalContent = styled.div`
  padding: 16px;

  button {
    margin-top: 20px;
    font-weight: bold;
  }
`
export const PrivacySettingsItemsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const ButtonsWrapper = styled.ul`
  list-style: none;
  display: flex;
  gap: 10px;
`

export const CustomizeSettingsButton = styled.div`
  color: #0b84ff;
  font-size: 12px;
  width: fit-content;
  text-decoration: underline;
  cursor: pointer;
  margin: 5px 0;
`

export const Wrapper = styled.div`
  background-color: #000000;
  border: 2px solid var(--border-color);
  position: fixed;
  z-index: 999999999;
  bottom: 16px;
  left: 16px;
  padding: 12px;
  border-radius: 10px;
  width: calc(100% - 32px);
  max-width: 500px;
`

export const Title = styled.p`
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
`

export const Description = styled.p`
  margin: 5px 0 0;
  font-size: 14px;
  color: #ffffff;
  line-height: 1.4;

  a {
    font-size: inherit;
    text-decoration: underline;
  }
`

export const ControllerBlock = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;

    > ${ButtonsWrapper} {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
`
